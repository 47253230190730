<template>
  <div class="posterCenter-container">
    <header class="poster-header">
      <van-nav-bar
          :title="titleName"
          left-arrow
          @click-left="handleBlack"
          safe-area-inset-top
      >
<!--        <template #right>-->
<!--          <icon-svg-->
<!--              iconClass="iconsearch"-->
<!--              @click="loadSearchPoster"-->
<!--              style="height: 24px; width: 24px; color: '#333333'"-->
<!--          />-->
<!--        </template>-->
      </van-nav-bar>
      <div class="lv-one" v-if="posterActive==0&&showLvOne">
        <div class="lv-one-type">
          <div :class="topType===1?'bc-color':''" @click="handleChoiceLvOne(1)">企业海报</div>
          <div :class="topType===2?'bc-color':''" @click="handleChoiceLvOne(2)">推荐海报</div>
        </div>
      </div>
      <div class="twotypeselect" v-if="posterActive===0">
        <div class="articleType-all" v-if="!showAdd">
          <van-tabs
              @click="loadTwoTypeList"
              v-model:active="twoTypeTab"
              swipe-threshold="1"
              color="#FF6E01"
              title-active-color="#FF6E01"
          >
            <van-tab v-if="showNiceChoice" title="精选"></van-tab>
            <van-tab
                v-for="it in twoTypeList"
                :key="it.categoryPrimaryId"
                :title="it.materialCategoryName"
            ></van-tab>
            <!--            <van-tab v-if="showMore" title="更多"></van-tab>-->
          </van-tabs>
        </div>
        <div class="more-type" @click="handleMoreType" v-if="!showAdd">更多</div>
      </div>
      <!-- 二级 -->
<!--      <div class="articleType-all" v-if="posterActive===0">-->
<!--        <van-tabs-->
<!--            @click="loadTwoTypeList"-->
<!--            v-model:active="twoType"-->
<!--            swipe-threshold="4"-->
<!--            color="#FF6E01"-->
<!--            title-active-color="#FF6E01"-->
<!--        >-->
<!--          <van-tab v-if="showNiceChoice" title="精选"></van-tab>-->
<!--          <van-tab-->
<!--              v-for="it in twoTypeList"-->
<!--              :key="it.typeId"-->
<!--              :title="it.typeName"-->
<!--          ></van-tab>-->
<!--          <van-tab v-if="showMore" title="更多"></van-tab>-->
<!--        </van-tabs>-->
<!--      </div>-->
    </header>
    <article class="poster-article" id="poster">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoadMore"
            :offset="50"
        >
          <div class="data-list-box" id="data-list-box" ref="dataBox">
            <div class="data-item" v-for="(item, index) in posterList"
                   :key="index" :style="{width: boxWidth + 'px'}" >
              <div class="poster-imgbox-share" @click="handleShare(item)"><icon-svg iconClass="iconfenxiang" class="share"/></div>
              <div class="poster-imgbox-del" v-if="posterActive===1" @click="handleDelPoser(item)"><icon-svg iconClass="icontrash-2-copy" class="share"/></div>
              <img class="data-cover" :src="item.posterImgUrl?item.posterImgUrl:defaultImg" @click="loadToDetails(item)" :style="{width: '100%'}"/>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </article>
    <footer class="poster-footer">
      <div class="add_article" @click="handleAddPoster">+</div>
      <div class="article_list_buttom2">
        <van-tabbar v-model="posterActive" active-color="#289FFF" @change="loadChoicePosterType">
          <van-tabbar-item>
            <icon-svg
                iconClass="iconweixuanzhong3"
                v-show="!chioceAll"
                class="allArt"
            />
            <icon-svg
                iconClass="iconxuanzhong3"
                v-show="chioceAll"
                class="allArt"
            />
            <span>海报列表</span>
          </van-tabbar-item>
          <van-tabbar-item>
            <!-- <img :src="active ? icon.mycaseN : icon.mycaseC"/> -->
            <icon-svg
                iconClass="iconwodehaibaoweixuanzhong"
                v-show="!chioceMy"
                class="allArt"
            />
            <icon-svg
                iconClass="iconwodehaibaoxuanzhong"
                v-show="chioceMy"
                class="allArt"
            />
            <span>我的海报</span>
          </van-tabbar-item>
        </van-tabbar>
      </div>
    </footer>
    <!-- 删除 -->
    <div class="del-wrapper">
      <van-overlay :show="showDel" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p>删除</p>
            <p>确定删除该海报？</p>
            <van-grid clickable :column-num="2">
              <van-grid-item @click="handleConfirmDel('cancel')"
              ><span>取消</span></van-grid-item
              >
              <van-grid-item @click="handleConfirmDel"
              ><span>确定</span></van-grid-item
              >
            </van-grid>
          </div>
        </div>
      </van-overlay>
    </div>
<!--  无二维码，跳转到详情  -->
    <div class="del-wrapper">
      <van-overlay :show="showToPoster" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p>确认</p>
            <p>暂无跳转地址，请先更换海报地址</p>
            <van-grid clickable :column-num="2">
              <van-grid-item @click="handleToDetail('cancel')"
              ><span>取消</span></van-grid-item
              >
              <van-grid-item @click="handleToDetail"
              ><span>确定</span></van-grid-item
              >
            </van-grid>
          </div>
        </div>
      </van-overlay>
    </div>
    <!-- 弹出分享框 -->
    <div class="share_box">
      <van-share-sheet
          v-model:show="showShare"
          title="分享"
          :options="options"
          cancel-text="取消"
          @select="onSelect"
          safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>
    <!-- 更多分类弹出框 -->
    <van-overlay :show="showMoreType">
      <div class="wrapper" @click.stop>
        <div class="select-like-type">
          <icon-svg iconClass="iconx" class="cancel" @click="closeLoadMoreType" />
          <p class="title">选择你喜欢的类别</p>
          <div class="grid-type">
            <div
                :class="['grid-type-item', moreBox.includes(it) ? 'bgColor' : '']"
                v-for="it in moreTypeList"
                :key="it.categoryPrimaryId"
                @click="handleChoice(it)"
            >
              {{ it.materialCategoryName }}
            </div>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
          <div class="confirm" @click="loadConfirm">确认</div>
        </div>
      </div>
    </van-overlay>
<!--      <div class="wrapperPoster" id="saveposter" style="z-index: 9999" v-if="showOverlay">-->
<!--        <div class="poster-details" v-if="showImg" ref="imageWrapper">-->
<!--          <img :src="posterPic" crossOrigin="anonymous"/>-->
<!--          <div class="text-qrcode">-->
<!--            <div class="left-text">-->
<!--              <p>{{ posterName }}</p>-->
<!--              <p>分享给朋友</p>-->
<!--            </div>-->
<!--            <div class="qrCodeUrl-all">-->
<!--              <div class="qrCodeUrl-cls">-->
<!--                <img v-if="qrCodeUrl" :src="qrCodeUrl"/>-->
<!--              </div>-->
<!--              <p>长按识别</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-else class="posterImg-hb">-->
<!--          <img class="card-img" :src="imgSrc" ref="cardImgRef" />-->
<!--        </div>-->
<!--      </div>-->

    <div class="wrapperPoster" id="saveposter" style="z-index: 9999" v-if="showOverlay">
      <div class="poster-details" v-if="showImg" ref="imageWrapper">
<!--        <img :src="posterPic" crossOrigin="anonymous"/>-->
        <div class="poster-img">
          <img :src="posterPic" class="main-img" crossOrigin="anonymous"/>
          <img v-if="merchantLogoUrl" :src="merchantLogoUrl" class="logo-img" >
        </div>
        <div class="text-qrcode">
          <div class="user-info-tag">
            <div class="user-info">
              <img :src="headImgUrl">
              <div class="left-text">
                <p>{{ userForm.name }}</p>
                <p>{{ userForm.merchantName }}</p>
              </div>
            </div>
            <div class="user-tag">
              <span class="user-tag-item" v-for="(it,index) in userTags" :key="index">{{ it }}</span>
            </div>
          </div>
          <div class="qrCodeUrl-all">
            <div class="qrCodeUrl-cls">
              <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
            </div>
            <p>立即扫码</p>
            <p>了解更多内容</p>
          </div>
        </div>
      </div>
      <div v-else class="posterImg-hb">
        <img class="card-img" :src="imgSrc" ref="cardImgRef" />
      </div>
    </div>
<!--    </van-overlay>-->
  </div>
</template>
<script>
import {reactive, toRefs, nextTick, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {
  getPosterListApp,
  getMyPosterListApp,
  deletePosterApp,
  reprintPoster,
  getPosterAppDetails,
} from "../../api/posterCenter";
// import { sharePosterToMiniProgram } from "@/utils/commonUtil";
import { Toast } from "vant";
import html2canvas from "html2canvas";
import { uploadCoverPic} from "@/api/file/index";
import {flutterShowToast} from "@/utils/appChannel";
import { loadImages } from "@/utils/commonUtil";
import { getMerchantMaterialFullCategory } from "../../api/articleManagement";
import {getMerchantManagementScope} from "../../api/huokeArticle";
import {getUserCardByPoster} from "@/api/user/userCard"

export default {
  name:'posterIndex',
  activated(){
    document.getElementById('poster').scrollTop = Number(sessionStorage.getItem('scrollTop'))
  },
  deactivated(){
    let scrollTop = document.getElementById('poster').scrollTop
    sessionStorage.setItem('scrollTop',scrollTop)
  },
  setup(){
    const router = useRouter()
    const route = useRoute()
    const dataBox = ref(null)
    const imageWrapper = ref(null);
    const cardImgRef = ref(null);
    const state = reactive({
      merchantLogoUrl:'',
      userForm:{},
      userTags:[],
      headImgUrl:'',
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      topType:1,
      showLvOne:false,
      showToPoster:false,
      twoTypeTab:0,
      endIndex:10000,
      defaultImg:require("@/assets/images/poster-default.jpg"),
      defaultMiniImg:require("@/assets/images/poster-default-mini.jpg"),
      smallPosterImgUrl:'',
      bigPosterImgUrl:'',
      posterId:'',
      imgSrc:'',
      qrCodeUrl:'',
      posterName:'',
      posterPic:'',
      showOverlay: false,
      showImg: true,
      showDel:false,
      showShare:false,
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
      ],
      lastRowHeights: [0, 0],
      itemCount:0,
      boxMargin: 10, //每个item之间的边距
      boxWidth: 172, //每个item宽度
      baseUrl:`${process.env.VUE_APP_WATCH_FILE_API}/`,
      loading: false,
      finished: false,
      refreshing:false,
      posterTypeStr:'',
      moreBox:[],
      showMoreType:false,
      moreTypeList:[],
      showNiceChoice:false,
      showMore:false,
      twoTypeList:[],
      twoType:0,
      showAdd: false,
      titleName:route.query.isCreate?'我的海报':'海报列表',
      posterActive:route.query.isCreate?1:0,
      chioceAll:route.query.isCreate?false:true,
      chioceMy:route.query.isCreate?true:false,
      getDataList:[],
      posterList:[],
      myPosterParams:{
        current:1,
        total:1
      },
      posterListParams:{
        current:1,
        total:1,
        typeId:'',
      },
      posterItem:{}
    })
    watch(()=>state.showShare,(newStr,oldStr)=>{
      console.log("newStr========>",newStr)
      console.log("oldStr===========>",oldStr)
      if(newStr === false){
        state.showOverlay = false
      }
    })

    let screenWidth = document.body.offsetWidth; //屏幕宽度
    state.boxWidth = (screenWidth - state.boxMargin*3)/2; //每个item的宽度
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    //顶部tab
    const getMerchantManagement = async () =>{
      let res = await getMerchantManagementScope()
      state.showLvOne = res
      console.log(res)
      if (!res){
        state.topType = 0
      }
      // loadArticleMoreType();
    }
    getMerchantManagement()
    const handleChoiceLvOne = (val) =>{
      state.twoType=0
      state.posterListParams.typeId = ''
      state.posterListParams.current = 1
      state.topType = val
      dataBox.value.style.height = "0px"
      state.posterList = []
      state.itemCount = 0
      state.lastRowHeights=[0, 0]
      state.finished = false
      getPosterAllType()
      onLoadMore()
    }
    //打开分享微信
    const handleShare = (item) => {
      Toast.loading({
        message: '生成海报中...',
        forbidClick: true,
        duration:0,
        className:'posterToast',
      });
      getUserCardByPoster().then(res=>{
        if (res){
          if (res.headImgUrl){
            state.headImgUrl = state.imgUrl+'/'+res.headImgUrl
          }else {
            state.headImgUrl = require("@/assets/poster_headerImg_default.png")
          }

          state.userForm = res
          state.userTags = res.impressionLabel.split(',')
          if (state.userTags.length>4){
            state.userTags = state.userTags.slice(0,4)
          }
        }
      })
      console.log("分享的海报",item)
      state.posterId = item.posterId
      getPosterAppDetails(item.posterId).then((res)=>{
        state.posterItem = item;
        state.posterPic = item.posterImgUrl?item.posterImgUrl:state.defaultImg
        if (res.merchantLogoUrl){
          state.merchantLogoUrl = state.imgUrl+'/'+res.merchantLogoUrl
        }else {
          state.merchantLogoUrl = ''
        }
        state.posterName = item.posterName
        state.posterId = item.posterId
        state.smallPosterImgUrl = item.smallPosterImgUrl
        state.bigPosterImgUrl = item.posterImgUrl
        if(res.qrCodeUrl){
          state.qrCodeUrl = res.qrCodeUrl
        }
        if(state.qrCodeUrl&&state.qrCodeUrl.indexOf("data:image/jpg;base64,null")===-1){
          // state.showShare = true
          state.showImg = true
          state.showShare = true;
          state.showOverlay = true;
          // setTimeout(()=>{
          //   savePoster()
          // },100)
          let arr = []
          arr.push(state.posterPic)
          arr.push(state.qrCodeUrl)
          arr.push(state.headImgUrl)
          if (state.merchantLogoUrl){
            arr.push(state.merchantLogoUrl)
          }
          loadImages(arr,loadPoster)
        }else {
          state.showToPoster = true
        }
      })
    };

    //微信分享
    // const onSelect = (index) => {
    //   sharePosterToMiniProgram(state.posterItem, index);
    // };
    const loadPoster = ()=>{
      savePoster()
      setTimeout(()=>{
        Toast.clear()
      })
    }
    //微信分享
    const onSelect = async (index) => {
      Toast.loading({
        message: '正在\n打开微信...',
        forbidClick: true,
        duration:3000,
        className:'posterToast',
      });
      let posterFile = await dataURLtoFile(state.imgSrc,'png')
      console.log("海报图片",posterFile)
      if(posterFile){
        let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        formData.append("file", posterFile); //接口需要传的参数
        formData.append("busiId", state.posterId); //接口需要传的参数
        formData.append("busiCat", "wxShare"); //接口需要传的参数
        formData.append("groupId", "posterImg"); //接口需要传的参数
        uploadCoverPic(formData)
            .then((data) => {
              let item = {
                url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
                fileId: data.fileId,
              };
              console.log("上传后的海报地址===============",item.url);
              let shareType;
              let shareImg = state.bigPosterImgUrl?`${state.smallPosterImgUrl}`:'https://wca.ujiaku.com/upload/wx/poster-default-mini.jpg';//缩略图
              let content = `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`;//图片地址

              if(index.name=== "微信"){
                shareType = "shareImageToWeiXin"
              }else if(index.name === "朋友圈"){
                shareType = "shareImageToTimeLine"
              }
              let params = {
                shareType:shareType,
                shareImg:shareImg,
                urlEntity:{
                  content:content
                }
              }
              console.log(params);
              flutterShowToast(params);
              reprintPoster(state.posterId)
            })
            .catch((err) => {
              console.log(err);
            });
      }

    };
    //文件上传
    const uploadImg = (file)=>{
      let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file); //接口需要传的参数
      formData.append("busiId", state.posterId); //接口需要传的参数
      formData.append("busiCat", "wxShare"); //接口需要传的参数
      formData.append("groupId", "posterImg"); //接口需要传的参数
      uploadCoverPic(formData)
          .then((data) => {
            let item = {
              url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
              fileId: data.fileId,
            };
            state.sharePosterImg = item.url
            console.log("上传后的海报地址===============",item.url);
          })
          .catch((err) => {
            console.log(err);
          });
    }
    //base64转文件
    const dataURLtoFile=(dataurl, filename)=> {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    }
    //获取分类
    const getPosterAllType = async () =>{
      let  res = await getMerchantMaterialFullCategory({
        modeId:4,
        materialType:state.topType
      })
      console.log("海报分类",res)
      if(res){
        state.moreTypeList = res
        // state.twoTypeList = res.slice(0, 4)
        state.twoTypeList = res
        state.showNiceChoice = true
        state.showMore = true
      }
    }
    //选择更多分类
    const handleChoice = (it) => {
      if (state.moreBox.includes(it)) {
        state.moreBox.splice(state.moreBox.indexOf(it), 1);
        state.posterTypeStr = state.posterTypeStr
            .split(`${it.categoryPrimaryId.toString()},`)
            .join("");
      } else {
        state.moreBox.push(it);
        if (state.posterTypeStr === "") {
          state.posterTypeStr = it.categoryPrimaryId.toString() + ",";
        } else {
          state.posterTypeStr = state.posterTypeStr + it.categoryPrimaryId.toString() + ",";
        }
      }
    };
    //打开更多分类查询
    const handleMoreType = () =>{
      loadTwoTypeList(10000)
    }
    //二级分类
  const loadTwoTypeList = (val) =>{
    if(val!==0&&val!==10000){
      state.twoTypeList.map((it,index)=>{
        if(index+1===val){
          state.twoType = it.categoryPrimaryId
        }
      })
    }else {
      if(val===0){
        state.twoType = 0
      }else {
        state.twoType = val
      }
    }
    state.posterListParams.typeId = state.twoType===0||state.twoType===state.endIndex?'':state.twoType
    state.posterListParams.current = 1
    if (state.twoType === state.endIndex){
      state.showMoreType = true
    }else{
      // state.loading = true
      dataBox.value.style.height = "0px"
      state.posterList = []
      state.itemCount = 0
      state.lastRowHeights=[0, 0]
      state.finished = false
      onLoadMore()
    }
  }
    getPosterAllType()
    const loadSearchPoster = () =>{
      console.log("搜索海报中心")
    }
    const handleBlack = () =>{
      router.push('/')
    }

    //一级分类
    const  loadChoicePosterType = () =>{
      console.log("选择海报分类",state.posterActive)
      dataBox.value.style.height = "0px"
      console.log("列表盒子元素",dataBox.value.style.height)
      state.posterList = []
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      state.itemCount = 0
      state.lastRowHeights=[0, 0]
      if(state.posterActive===0){
        state.titleName = '海报列表'
        state.chioceAll = true
        state.chioceMy = false
        state.posterListParams.current = 1
        state.loading = false
        state.finished = false
        onLoadMore()
      }else{
        state.titleName = '我的海报'
        state.chioceAll = false
        state.chioceMy = true
        state.myPosterParams.current = 1
        state.loading = false
        state.finished = false
        onLoadMore()
      }
    }
    const handleAddPoster = () =>{
      router.push({
        path:'/createPoster'
      })
      console.log("添加海报")
    }
    //更多分类搜索
    const loadConfirm = () =>{
      state.twoTypeTab = 0
      state.posterList = []
      dataBox.value.style.height = "0px"
      state.itemCount = 0
      state.lastRowHeights=[0, 0]
      state.posterListParams.typeId = state.posterTypeStr
      onLoadMore()
      state.showMoreType = false
    }
    //关闭更多分类
    const closeLoadMoreType = () => {
        state.showMoreType = false;
    };
    //下拉刷新
    const onRefresh = () =>{
      console.log("下拉刷新。。。。")
      state.posterList = []
      state.itemCount = 0
      state.lastRowHeights=[0, 0]
      if(state.posterActive===0){
        state.posterListParams.current = 1
        state.finished = false
        onLoadMore()
      }else{
        state.myPosterParams.current = 1
        state.finished = false
        onLoadMore()
      }
    }
    //上拉加载更多
    const onLoadMore = async () =>{
      state.loading = true
      let params = {}
      if (state.posterActive===0){
        console.log("海报列表")
        params = {
          isMaterial:1,
          current:state.posterListParams.current,
          typeId:state.posterListParams.typeId,
          materialType:state.topType
        }
        if (state.posterList.length<=state.posterListParams.total){
          loadData(params)
          state.itemCount = state.posterList.length
        }
        // else {
        //   state.finished = true
        // }
        nextTick(()=>{
          setTimeout(()=>{
            //渲染完成，计算每个item宽高，设置标签坐标定位
            setItemElementPosition();
            state.loading = false
            state.refreshing = false
            Toast.clear()
          }, 1000)
        });
      }else {
        console.log("我的海报")
        params = {
          current:state.myPosterParams.current
        }
        if (state.posterList.length<state.myPosterParams.total){
          loadData(params)
          state.itemCount = state.posterList.length
        }else {
          console.log("停止加载我的海报")
          state.finished = true
        }
        nextTick(()=>{
          setTimeout(()=>{
            //渲染完成，计算每个item宽高，设置标签坐标定位
              setItemElementPosition();
            state.loading = false
            state.refreshing = false
            Toast.clear()
          }, 1000)
        });
      }
    }
    //加载海报列表
    const loadData= async (params)=>{
      state.loading = true
      if(state.posterActive === 0){
        getPosterListApp(params).then((res)=>{
          if (res.records.length>0){
            state.posterListParams.current = state.posterListParams.current+1
            state.posterListParams.total = res.total
            state.getDataList  = res.records
            state.getDataList.map(item =>{
              if (item.posterImgUrl){
                item.posterImgUrl = `${state.baseUrl}${item.posterImgUrl}`
              }
              if (item.smallPosterImgUrl){
                item.smallPosterImgUrl = `${state.baseUrl}${item.smallPosterImgUrl}`
              }
            })
            state.posterList.push(...state.getDataList)
            // if (state.posterList.length<state.posterListParams.total){
            //   console.log("海报列表",state.posterList)
            // }else {
            //   state.finished = true
            // }
          }else {
            state.finished = true
          }
        })
      }else {
        getMyPosterListApp(params).then((res)=>{
          if (res.records){
            state.myPosterParams.current = state.myPosterParams.current+1
            state.myPosterParams.total = res.total
            state.getDataList  = res.records
            state.getDataList.map(item =>{
              if (item.posterImgUrl){
                item.posterImgUrl = `${state.baseUrl}${item.posterImgUrl}`
              }
              if (item.smallPosterImgUrl){
                item.smallPosterImgUrl = `${state.baseUrl}${item.smallPosterImgUrl}`
              }
            })
            // loadImagesHeight(state.getDataList)
            state.posterList.push(...state.getDataList)
            if (state.posterList.length<state.myPosterParams.total){
              console.log("我的海报列表",state.posterList)
            }else {
              state.finished = true
            }
          }else {
            state.finished = true
          }
        })

      }

    }
    //处理图片高度
    //  const loadImagesHeight = (list) =>{
    //   var count = 0; //用来计数，表示是否所有图片高度已经获取
    //   list.forEach((item, index)=>{
    //     //创建图片对象，加载图片，计算图片高度
    //     var img = new Image();
    //     img.src = item.posterImgUrl;
    //     img.onload = img.onerror = (e)=>{
    //       count++;
    //       if (e.type == 'load'){ //图片加载成功
    //         //计算图片缩放后的高度：图片原高度/原宽度 = 缩放后高度/缩放后宽度
    //         list[index].imgHeight = Math.round(img.height * state.boxWidth / img.width);
    //         console.log("图片加载成功==========")
    //       }
    //       else{ //图片加载失败，给一个默认高度50
    //         list[index].imgHeight = 50;
    //         console.log("index： ", index, "， 加载报错：", e);
    //       }
    //       //加载完成最后一个图片高度，开始下一步数据处理
    //       if (count === list.length){
    //         console.log('高度计算完成',count)
    //       }
    //     }
    //   })
    // }
    //获取每个item标签高度，设置item的定位
    const setItemElementPosition = ()=>{
      console.log("设置标签高度--------------------")
      let parentEle = document.getElementById('data-list-box');
      let boxEles = parentEle.getElementsByClassName("data-item");
      for (let i = state.itemCount; i < boxEles.length; i++){
        let tempEle = boxEles[i];
        //上一个标签最小高度的列索引
        let curColIndex = getMinHeightIndex(state.lastRowHeights);
        let boxTop = state.lastRowHeights[curColIndex] + state.boxMargin;
        let boxLeft = curColIndex * (state.boxWidth + state.boxMargin) + state.boxMargin;
        tempEle.style.left = boxLeft + 'px';
        tempEle.style.top = boxTop + 'px';
        state.lastRowHeights[curColIndex] = boxTop + tempEle.offsetHeight;
        // console.log('i = ', i, ', boxTop: ', boxTop, ', eleHeight: ', tempEle.offsetHeight);
      }
      state.itemCount = boxEles.length;
      //修改父级标签的高度
      let maxHeight = Math.max.apply(null, state.lastRowHeights);
      parentEle.style.height = maxHeight + 'px';
      console.log("父盒子高度====",maxHeight)
      // this.$toast.clear();
    }
    //获取数组中最小值的索引
    const getMinHeightIndex = (arr)=>{
      var minHeight = Math.min.apply(null, arr);
      for (let i = 0; i < arr.length; i++){
        if (arr[i] == minHeight){
          return i;
        }
      }
    }
    //进入详情
    const loadToDetails = (it) =>{
      console.log("进入详情",it)
      router.push({
        path:'/posterDetails',
        query:{
          posterId:it.posterId
        }
      })
    }
    //删除海报
    const handleDelPoser = (item) =>{
      console.log("删除海报",item)
      state.posterItem = item
      state.showDel = true
    }
    const handleToDetail = (val) =>{
      if(val==='cancel'){
        state.showToPoster = false
      }else {
        let posterId = state.posterId
        router.push({
          path:'/posterDetails',
          query:{
            posterId:posterId
          }
        })
        state.showToPoster = false
      }
    }

    const handleConfirmDel = (val) =>{
      if(val==='cancel'){
        state.showDel = false
      }else {
        deletePosterApp(state.posterItem.posterId).then((res)=>{
          if (res){
            // Toast.success("删除成功")
            Toast.loading({
              message: '删除中...',
              forbidClick: true,
              duration:0
            });
            state.showDel = false
            state.posterList.map((it,index) =>{
              if (it.posterId === state.posterItem.posterId){
                state.posterList.splice(index,1)
              }
            })
            onRefresh()
            Toast.clear()
          }
        })
      }
    }
    //画图
    const savePoster = () =>{
      console.log("保存")
      state.showOverlay = true;
      // html2canvasChange()
      nextTick(() => {
        console.log("imageWrapper>>>>>>>>>>>>>>>>>>>", imageWrapper.value);
        // var canvas2 = document.createElement("canvas");
        let _canvas = imageWrapper.value;
        const box = window.getComputedStyle(_canvas);
        // DOM 节点计算后宽高
        const width = parseInt(box.width, 10);
        const height = parseInt(box.height, 10);
        // 获取像素比
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        html2canvas(_canvas, {
          // scale:3,
          useCORS: true,
          background: "#ffffff",
          height: document.getElementById('saveposter').scrollHeight,
          windowHeight: document.getElementById('saveposter').scrollHeight,
          canvas,
        }).then((canvas) => {
          var context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let dataURL = canvas.toDataURL("image/png");
          state.showImg = false
          nextTick(() => {
            cardImgRef.value.style.width = width + "px";
            cardImgRef.value.style.height = height + "px";
            state.imgSrc = dataURL;
            state.showImg = false
          });
        });
      });
    }
    const DPR = () => {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    };
    return {
      handleChoiceLvOne,
      handleToDetail,
      ...toRefs(state),
      dataURLtoFile,
      uploadImg,
      DPR,
      savePoster,
      imageWrapper,
      cardImgRef,
      dataBox,
      handleConfirmDel,
      handleDelPoser,
      getMinHeightIndex,
      // loadImagesHeight,
      loadSearchPoster,
      handleBlack,
      loadChoicePosterType,
      handleAddPoster,
      closeLoadMoreType,
      handleChoice,
      loadTwoTypeList,
      onRefresh,
      onLoadMore,
      loadToDetails,
      setItemElementPosition,
      loadConfirm,
      handleShare,
      onSelect,
      handleMoreType
    }
  }

}
</script>
<style lang="scss">
.posterToast{
  //color: #289fff;
  z-index: 1000000001 !important;
}
.posterCenter-container{
  .poster-header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .twotypeselect{
      display: flex;
      justify-content: space-between;
      .articleType-all {
        overflow-x: hidden;
        .van-tabs__wrap {
          border-bottom: 0.5px solid #f1f3f6;
        }
        .van-tab {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
          padding: 0 20px;
          //flex: auto;
          //   border-bottom: 1px solid #cccccc;
        }
        .van-tab--active {
          color: #323233;
          font-size: 12px;
          font-weight: 600;
        }
      }
      //.tabs-main{
      //  flex: 1;
      //  display: flex;
      //  width: 200px;
      //  overflow-y:hidden;
      //  li{
      //    width: auto;
      //    float: left;
      //  }
      //}
      .more-type{
        width: 100px;
        height: 50px;
        color: #666666;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        line-height: 45px;
        padding: 0 10px;
      }
    }
    .lv-one{
      padding:0 16px;
      font-size: 14px;
      font-weight: 600;
      .lv-one-type{
        display: flex;
        justify-content: space-between;
        height: 38px;
        >div{
          line-height: 38px;
        }
        :nth-child(1){
          border-radius: 4px 0px 0px 4px;
          border: .4px solid #999999;
          flex: 1;
        }
        :nth-child(2){
          flex: 1;
          border-radius: 0px 4px 4px 0px;
          border: .4px solid #999999;
        }
        .bc-color{
          background-color: #FF6E01;
          color: #FFFFFF;
          border: none;
        }
      }
    }
  }
  .poster-article{

  }
  .poster-footer {
    position: relative;
    height: 50px;
    .van-tabbar-item__text {
      img {
        height: 24px;
        width: 24px;
      }
    }

    .add_article {
      height: 36px;
      width: 52px;
      color: #fff;
      line-height: 36px;
      text-align: center;
      border-radius: 8px;
      font-size: 28px;
      position: fixed;
      z-index: 2;
      left: 50%;
      bottom: 7px;
      transform: translateX(-50%);
      background-color: #ff6e01;
    }
    .van-tabbar-item__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        display: block;
        margin-left: 12px;
      }
      .allArt {
        display: block;
        text-align: center;
        font-size: 20px;
      }
    }
  }
  // 分享
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
  //  删除
  .del-wrapper {
    .van-overlay {
      z-index: 102;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        font-size: 14px;
        width: 303px;
        height: 133px;
        border-radius: 10px;
        color: #333333;
        background-color: #fff;
        > div {
          > div:nth-child(1) {
            .van-hairline {
              border-radius: 0 0 0 10px;
              padding: 0;
              color: #999999;
            }
          }
          > div:nth-child(2) {
            .van-hairline {
              border-radius: 0 0 10px 0;
              padding: 0;
            }
            [class*="van-hairline"]::after {
              border-right: none;
            }
          }
        }
        [class*="van-hairline"]::after {
          border-bottom: none;
        }
        > p {
          font-weight: 600;
          font-size: 14px;
          margin: 17px 16px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 46px;
          // height: 100%;
          // width: 100%;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.posterCenter-container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  img{
    padding: 0;
    margin: 0;
  }
  //遮罩层
  .van-overlay {
    z-index: 3;
    height: 100%;
    width: 100%;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .select-like-type {
        position: absolute;
        height: 365px;
        width: 344px;
        left: 16px;
        top: 88px;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 8px;
        background-color: #fff;
        .cancel {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 12px;
          right: 12px;
        }
        .title {
          font-size: 18px;
          font-weight: 600;
          line-height: 10px;
        }
      }
      .grid-type {
        padding-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        // border-radius: 10px;
        justify-content: space-between;
        >i{
          height: 28px;
          width: 72px;
        }
        .grid-type-item {
          height: 28px;
          width: 72px;
          margin-bottom: 8px;
          border-radius: 5px;
          font-size: 12px;
          line-height: 28px;
          background-color: #f1f3f6;
        }
        .bgColor {
          color: #ffffff;
          background-color: #ffcf23;
        }
      }
      .confirm {
        position: absolute;
        height: 28px;
        width: 72px;
        color: #ffffff;
        font-size: 12px;
        line-height: 28px;
        background-color: #ff6e01;
        border-radius: 5px;
        margin-left: 50%;
        left: -36px;
        // transform:translateX(-50%);
        bottom: 20px;
      }
    }
  }
  .poster-header {
    font-size: 18px;
  }
  .poster-article{
    overflow-x: hidden;
    flex: 1;
    ///* 列表数据样式 */
    @keyframes data-item-ani{
      0% { transform: scale(1); }
      100% { transform: scale(1); }
    }
    //@keyframes fade-in {
    //  0% {opacity: 0;}/*初始状态 透明度为0*/
    //  40% {opacity: 0;}/*过渡状态 透明度为0*/
    //  100% {opacity: 1;}/*结束状态 透明度为1*/
    //}
    //@-webkit-keyframes fade-in {/*针对webkit内核*/
    //  0% {opacity: 0;}
    //  40% {opacity: 0;}
    //  100% {opacity: 1;}
    //}
    //.data-list-box { position: relative; margin-height: 100vh; margin-top: 40px; }
    .data-list-box{
      position: relative;
      .data-item {
        height: auto;
        position: absolute;
        left: -1000px;
        animation: data-item-ani;
        transition: left 0.3s, top 0.3s;
        //transition-delay: 0.5s;
        //animation: fade-in;/*动画名称*/
        //animation-duration: 2s;/*动画持续时间*/
        //-webkit-animation:fade-in 2s;/*针对webkit内核*/
        //opacity: 1;
        .poster-imgbox-share{
          position: absolute;
          //margin-left: 140px;
          //margin-top: 8px;
          right: 8px;
          top: 8px;
          height: 22px;
          width: 22px;
          background-color: #323233;
          border-radius: 11px;
          opacity:0.5;
          font-size: 12px;
          line-height: 22px;
          text-align: center;
          .share{
            color: #FFFFFF;
          }
        }
        .poster-imgbox-del{
          position: absolute;
          //margin-left: 140px;
          //margin-top: 8px;
          right: 8px;
          top: 40px;
          height: 22px;
          width: 22px;
          background-color: #323233;
          border-radius: 11px;
          opacity:0.5;
          font-size: 12px;
          line-height: 22px;
          text-align: center;
          .share{
            color: #FFFFFF;
          }
        }
      }
    }
    .data-item{
      .data-cover{ display: block; }
    }
  }
  .poster-footer{
    height: 50px;
  }
 .wrapperPoster{
   //margin-top: 100px;
   height: 400px;
   overflow-x: hidden;
   position: absolute;
   top: 60px;
   //bottom: 0;
   left: 0;
   right: 0;
   .poster-details{
     position: relative;
     left: 50%;
     transform: translateX(-50%);
     width: 250px;
     overflow-x: hidden;
     .poster-img{
       width: 250px;
       position: relative;
       .main-img{
         width: 100%;
         display: block;
       }
       .logo-img{
         position: absolute;
         width: 50px;
         top: 18px;
         left: 50%;
         transform: translate(-50%,0%);
       }
     }
     >img{
       width: 250px;
       display: block;
     }
     //.text-qrcode{
     //  background-color: #ffff;
     //  display: flex;
     //  justify-content: space-between;
     //  padding: 10px;
     //  .left-text{
     //    text-align: left;
     //    p {
     //      margin: 0;
     //      padding: 0;
     //    }
     //    p:nth-child(1){
     //      font-size: 14px;
     //      font-weight: 400;
     //      line-height: 18px;
     //      color: #333333;
     //    }
     //    p:nth-child(2){
     //      font-size: 9px;
     //      color: #999999;
     //      line-height: 20px;
     //    }
     //  }
     //  .qrCodeUrl-all{
     //    position: relative;
     //    text-align: center;
     //    height: 50px;
     //    .qrCodeUrl-cls{
     //      width: 50px;
     //      height: 50px;
     //      background-color: #FFFFFF;
     //      border-radius: 25px;
     //      position: relative;
     //      top: -26px;
     //      text-align: center;
     //      img{
     //        width: 40px;
     //        border-radius: 20px;
     //        position: relative;
     //        margin-top: 50%;
     //        transform: translateY(-50%);
     //      }
     //    }
     //    >p{
     //      position: relative;
     //      font-size: 9px;
     //      font-weight: 400;
     //      color: #999999;
     //      top: -40px;
     //    }
     //  }
     //}
     .text-qrcode{
       background-color: #ffff;
       display: flex;
       justify-content: space-between;
       padding: 10px;
       flex: 1;
       .user-info-tag{
         .user-info{
           display: flex;
           align-items: center;
           img{
             border-radius: 20px;
             width: 40px;
             height: 40px;
             object-fit: cover;
             flex-shrink: 0;
           }
           .left-text{
             text-align: left;
             //flex: 1;
             margin-left: 4px;
             p {
               margin: 0;
               padding: 0;
             }
             p:nth-child(1){
               font-size: 16px;
               font-weight: 400;
               line-height: 18px;
               color: #333333;
             }
             p:nth-child(2){
               font-size: 10px;
               color: #333333;
               //line-height: 20px;
             }
           }
         }
         .user-tag{
           display: flex;
           flex-wrap: wrap;
           .user-tag-item{
             margin-top: 5px;
             margin-right: 5px;
             //padding-left: 5px;
             padding:2px 5px;
             color: #289FFF;
             background-color: #E6ECFF;
             font-size: 9px;
             border-radius: 4px;
           }
         }
       }


       .qrCodeUrl-all{
         //position: relative;
         //text-align: center;
         //height: 60px;
         border-left: 1px solid #F1F3F6;
         padding-left: 18px;
         margin-left: 8px;
         flex-shrink:0;
         .qrCodeUrl-cls{
           //width: 60px;
           //height: 60px;
           background-color: #FFFFFF;
           border-radius: 30px;
           //position: relative;
           //top: -33px;
           //text-align: center;
           img{
             width: 60px;
             //border-radius: 25px;
             //position: relative;
             //margin-top: 50%;
             //transform: translateY(-50%);
           }
         }
         >p{
           //position: relative;
           text-align: center;
           font-size: 10px;
           font-weight: 400;
           color: #666666;
           margin: 0;
           //top: -40px;
         }
       }
     }
   }
   .posterImg-hb{
     height: 400px;
     overflow-x: hidden;
   }
 }

}
</style>
